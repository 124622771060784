@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.6rem;
}

h1 {
  font-size: 6rem;
}

.app {
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: rgba(0,0,0,0.4);
  color: #fff;
}

.app:before {
  content: '';
  background: url('./assets/albany.jpeg') no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
}

.app .search {
  text-align: center;
  padding: 1rem;
}

.app input {
  padding: .7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255,255,255, 0.8);
  background: rgba(255,255,255, 0.1);
  color: #f8f8f8;
}

::placeholder {
  color: #f8f8f8;
}

.container {
  max-width: 800px;
  height: auto;
  margin: auto;
  padding:1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app .top {
  width: 100%;
  margin: 1rem auto;
}

.app .mid {
  padding: auto;
  align-items: center;
  width: 100%;
  margin:auto;
  justify-content: space-evenly;
  
}

.app .description {
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  align-self: auto;
 
}

.app .icon{
  position: relative;
  right: -90%;
}
.app .bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: 1rem auto;
  padding: auto;
  border-radius: 0.9rem;
  background-color: rgba(255,255,255, 0.1);
}

.bold {
  font-weight: 500;
  text-transform: capitalize;
}

@media screen  and (max-width:1024px) {
  .app .bottom {
    grid-template-columns: 1fr 1fr;
  }
  
}